var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-table", {
    staticClass: "gx-table-responsive",
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.clubs,
      loading: _vm.teamsLoading,
    },
    scopedSlots: _vm._u([
      {
        key: "operations",
        fn: function (text, row) {
          return _c(
            "div",
            { staticClass: "gx-text-left" },
            [
              _c(
                "router-link",
                { attrs: { to: "/club/" + row.id } },
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: {
                        "margin-left": "5px",
                        "margin-bottom": "0",
                      },
                      attrs: { type: "primary", size: "small" },
                    },
                    [_vm._v("\n        View\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }